
.support {
    width: 100%;
    display: flex;
    flex-direction: column;
    .sportsy-info {
        flex-grow: 1;
        width: 70%;
        margin: 2em auto;
        h1, h2, p {
            color: #666;
        }

        p {
            margin-bottom: 1em;
        }

        .effective-date {
            font-size: 12px;
        }

        ul {
            list-style-type: decimal;
            list-style-position: inside;

            li {
                margin-bottom: 1em;
                text-decoration: underline;
            }

            .privacy-info {
                margin-left: 1em;

                .privacy-nested-list {
                    list-style: lower-alpha;
                    margin-left: 1em;
                    .privacy-nested-sublist {
                        list-style: lower-roman;
                        margin-left: 1em;
                    }
                }
            }
        }
    }
}
