.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .product-container {
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        .subcontainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            align-content: center;
            margin-top: 32px;
            margin-bottom: 45px;
            margin-right: auto;
            margin-left: auto;
            .trainer-info {
                text-align: center;
                .trainer-img {
                    border-radius: 50%;
                    height: 100px;
                    width: 100px;
                    margin-bottom: 15px;
                }
                .trainer-name {
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 0;
                    margin-bottom: 5px;
                    color: #292929;
                }
                .trainer-location {margin-top: 0; color: #8B8B8B;}
            }

            .subscription-info {
                margin-bottom: 25px;
                .subscription-info-title {
                    font-size: 1em;
                    font-weight: 500;
                    color: #000000;
                }
                .subscription-info-content {
                    font-size: 0.95em;
                    padding-left: 20px;
                    color: #000000;
                }
            }

            .subscription-products-container {
                display: flex;
                flex-direction: column;
                width: 90%;
                justify-content: center;
                align-content: center;
                .subscription-button {
                    margin-bottom: 35px;
                    background-color: #F5F5F5;
                    border: 0;
                    border-radius: 5px;
                    padding: 0;
                    justify-content: space-between;
                    display: flex;
                    flex-direction: column;
                    .subscription-line {
                        height: 10px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        width: 100%;
                    }
                    .subscription-button-info {
                        text-align: left;
                        width: 80%;
                        margin-right: auto;
                        margin-left: auto;
                        .subscription-product-title {
                            font-size: 30px;
                            font-weight: bold;
                            color: #2A2A2A;
                        }
                        .subscription-product-price {font-size: 30px; color: #2A2A2A;}
                        .subscription-product-disclaimer {font-size: 20px; font-style: italic; color: #2A2A2A;}
                    }
                    .subscription-cta {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        width: 100%;
                        .subscription-cta-text {
                            color: white;
                            margin: 0;
                            padding: 1em;
                            font-size: 20px;
                            font-weight: 500px;
                        }
                    }
                }
            }
        }
    }
}