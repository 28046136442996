@import '../../styles/global';

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: $main-blue;
    min-height: 100vh;

    section {
        width: 90%;
        margin: 0 auto;

        .main-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;

            .hero-image {
                width: 30vh;
                margin-bottom: 1.5em;
            }

            .hero-subcontainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;

                .hero-text-container {
                    margin-bottom: 1em;

                    .hero-title {
                        text-shadow: 0px 2px 0px rgba(64,64,64,0.25);
                        font-family: oswald,Helvetica,Arial,sans-serif;
                        color: #fff;
                        font-size: 2em;
                        text-align: center;
                        line-height: 45px;
                        margin-bottom: 0.25em;
                        letter-spacing: 3px;
                    }
                    .hero-subtitle {
                        text-shadow: 0px 2px 0px rgba(64,64,64,0.25);
                        color: rgba(255,255,255,0.7);
                        font-family: roboto,Helvetica,Arial,sans-serif;
                        font-size: 1.25em;
                        text-align: center;
                        margin-top: 0;
                    }
                }

                .badge-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    margin: 0 auto 1em;

                    .apple-badge {}
                    .google-badge {}
                }
            }
        }
    }
}
