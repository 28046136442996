@import '../../styles/global';

header {
    width: 100%;
    background-color: $main-blue;

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4em 0 2em;

        @media (min-width: 1024px) {
            justify-content: flex-start;
            width: 90%;
            margin: 0 auto;
        }
    }

    .header-logo-container {
        .header-logo {
            width: 144px;
            @media (min-width: 1024px) {
                width: 100px;
            }
        }
    }
}