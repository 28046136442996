.container {
    width: 100%;
    display: flex; 
    flex-direction: column;
    .canceled-container {
        width: 90%;
        margin: 0 auto;
        flex-grow: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        .canceled-content {
            margin-bottom: 35px;
            justify-content: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .canceled-title {
                font-size: 32px;
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 30px;
            }
            .canceled-text {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
        .return-to-app {
            color: white;
            font-size: 16px;
            font-weight: bold;
            background: linear-gradient(to bottom, #0B96DF, #005083);
            width: 30%;
            padding-top: 15.5px;
            padding-bottom: 15.5px;
            border-radius: 30px;
            border: 0;
            @media (max-width: 1024px) {
                width: 70%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }
    }
}