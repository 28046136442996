.container {
    width: 100%;
    display: flex; 
    flex-direction: column;
    .password-reset-container {
        width: 90%;
        margin: 0 auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .passwordForm {
            display: flex;
            flex-direction: column;
            width: 30%;
            justify-content: space-between;
            .password-content {
                display: flex;
                flex-direction: column;
                margin-bottom: 1em;
                label {
                    margin-bottom: 0.5em;
                }
                input {
                    padding: 1em 0;
                }
            }
            .submit {
                margin-top: 1em;
                color: white;
                font-size: 16px;
                font-weight: bold;
                background: linear-gradient(to bottom, #0B96DF, #005083);
                width: 100%;
                padding-top: 15.5px;
                padding-bottom: 15.5px;
                border-radius: 30px;
                border: 0;
            }
        }
    }
}