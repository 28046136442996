@import '../../styles/global';

footer {
    background-color: $footer-background-color;
    padding: 1.5em 0 1em;
    width: 100%;
    .footer-container {
        width: 90%;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        @media (max-width: 1024px) {
            flex-direction: column;
        }

        .footer-link-container {
            display: flex;
            width: 30%;
            justify-content: space-between;
            align-content: center;
            align-items: center;

            @media (max-width: 1024px) {
                width: 50%;
                margin: 1em 0;
                justify-content: space-between;
            }

            @media (max-width: 600px) {
                width: 90%;
            }

            a {
                color: white;
                text-decoration: none;
            }
        }

        .footer-social-container {
            display: flex;
            width: 20%;
            justify-content: space-between;

            @media (max-width: 1024px) {
                width: 30%;
                margin-bottom: 1em;
                justify-content: space-between;
            }

            @media (max-width: 600px) {
                width: 50%;
                margin-bottom: 1em;
                justify-content: space-between;
            }
        }
    }

    .copyright {
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        p {
            color: white
        }
    }
}
