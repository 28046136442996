// Brand Colors
//$ml-grey-light: #f5f5f5;
//$ml-grey-mid: #454545;
//$mg-grey-dark: #161616;
$main-blue: #006ad8;

// Whites
$white: #FFFFFF;
$white-dark: #FCFCFC;
$white-overlay-1: rgba($white, .3);
$white-overlay-2: rgba($white, .4);
$white-overlay-3: rgba($white, .7);

// Blacks
$black-true: #000000;
$black: lighten($black-true, 25%);
$black-light: lighten($black-true, 40%);
$black-overlay-1: rgba($black-true, .15);
$black-overlay-2: rgba($black-true, .30);
$black-overlay-3: rgba($black-true, .50);
$black-overlay-4: rgba($black-true, .95);

// Greys
$grey: lighten($black-true, 60%);
$grey-light: lighten($black-true, 75%);
$grey-super-light: lighten($black-true, 97%);
$grey-shadow: rgba($black, 0.25);

// Update Bulma's global variables
//$grey-light: $ml-grey-light;
//$link: $ml-grey-mid;
$tablet: 768px;
// $desktop: 1258px;
//$widescreen: 1258px;
//$fullhd-enabled: false;

// Update some of Bulma's component variables
//$body-background-color: $mg-grey-dark;
$footer-background-color: #404040;
$footer-copyright: $black-overlay-3;
//$footer-padding: 12px;
//$control-border-width: 2px;
//$input-border-color: transparent;
//$input-shadow: none;